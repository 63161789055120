{
    "owid": [{
            "name": "source",
            "link": "https://www.ourworldindata.com",
            "link_text": "www.ourworldindata.com"
        },
        {
            "name": "introductory_paragraph",
            "title": "How to use the COVID-19 Observer",
            "text": "The COVID-19 Observer is a tool developed by the Africa Data Hub that facilitates the interrogation and analysis of COVID-19 data in Africa. The tool provides access to numerous metrics, including case numbers, deaths, testing, vaccinations and interventions. This data is updated daily from Our World In Data (OWID). Users are encouraged to use this interactive tool to drill down into country specific data, to overlay and compare key variables against each other as well as to choose certain time periods or dates. Journalists are also encouraged to download the data for their own analysis."
        },
        {
            "name": "table_description",
            "title": "About the Map and List",
            "text": "Using data from Our World In Data, the map and list ranks countries according to number of new cases in a 7-day rolling window. When viewing cases per million, the rankings are skewed towards smaller countries, so it is also useful and possible to switch between new cases and new cases per million to get a better understanding of the context."
        }
    ],
    "acdc": [{
            "name": "source",
            "link": "https://africacdc.org/",
            "link_text": "africacdc.org"
        },
        {
            "name": "table_description",
            "title": "About the Map and List",
            "text": "Using data from Our World In Data, the map and list ranks countries according to number of new cases in a 7-day rolling window. When viewing new cases per million, the rankings are skewed towards smaller countries, so it is also useful and possible to switch between new cases and new cases per million to get a better understanding of the context."
        },
        {
            "name": "introductory_paragraph",
            "title": "How to use the COVID-19 Observer",
            "text": "The COVID-19 Observer is a tool developed by the Africa Data Hub that facilitates the interrogation and analysis of COVID-19 data in Africa. The tool provides access to numerous metrics, including case numbers, deaths, testing and vaccinations. This data is updated daily from Africa CDC (ACDC). Users are encouraged to use this interactive tool to drill down into country specific data, to overlay and compare key variables against each other as well as to choose certain time periods or dates. Journalists are also encouraged to download the data for their own analysis."
        }
    ]
}
{
    "owid": [{
            "name": "new_cases",
            "type": "metric",
            "title": "",
            "source": "COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University",
            "category": "Confirmed cases",
            "owid_definition": "New confirmed cases of COVID-19",
            "text": "This value accounts for all new confirmed COVID-19 cases reported daily. This is different to the total cases which is cumulative. This data is not smoothed and it clearly shows the gaps where no data was reported on certain days. \nThis is a useful indicator to show the frequency of reporting on new confirmed COVID-19 cases."
        },
        {
            "name": "new_cases_smoothed",
            "type": "metric",
            "title": "",
            "source": "COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University",
            "category": "Confirmed cases",
            "owid_definition": "New confirmed cases of COVID-19 (7-day smoothed)",
            "text": "This is different to the total cases which is cumulative.\nData smoothing can be defined as a statistical approach of eliminating outliers from datasets to make the patterns clearer.\n7-day smoothed: average of a 7 day period.\nA full 7 days needs to be included to obtain an accurate picture of the data for the week. \nThis is commonly applied to COVID-19 data because not all health facilities report new cases, deaths or tests on a daily basis.\nThis value is a useful indicator to show the absolute number of new confirmed COVID-19 cases smoothed but it is more common to use new smoothed cases per million when comparing to other countries."
        },
        {
            "name": "new_cases_smoothed_per_million",
            "type": "metric",
            "title": "",
            "source": "COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University",
            "category": "Confirmed cases",
            "owid_definition": "New confirmed cases of COVID-19 (7-day smoothed) per 1,000,000 people",
            "text": "This is different to the total cases which is cumulative.\nData smoothing can be defined as a statistical approach of eliminating outliers from datasets to make the patterns clearer.\n7-day smoothed: average of a 7 day period.\nA full 7 days needs to be included to obtain an accurate picture of the data for the week. \nThis is commonly applied to COVID-19 data because not all health facilities report new cases, deaths or tests on a daily basis.\nCountries around the world each have different population sizes and comparing new confirmed COVID-19 cases between vastly different countries isn't necessarily helpful. By comparing per million, it helps give a relative size and allows better comparisons between countries."
        },
        {
            "name": "total_cases",
            "type": "metric",
            "title": "",
            "source": "COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University",
            "category": "Confirmed cases",
            "owid_definition": "Total confirmed cases of COVID-19",
            "text": "This is the cumulative value of all confirmed COVID-19 cases reported by the official health ministries from the start of the pandemic. Total cases does not necessarily capture all active COVID-19 cases. Cases here means patients that have tested positive. There may be asymptomatic people who have not been tested and are therefore not accounted for here.  For this reason, the positive rate is often used as a more accurate indicator of the prevalence of the virus.\nThis is an absolute value. It is better to use the total cases per million when comparing to other countries."
        },
        {
            "name": "total_cases_per_million",
            "type": "metric",
            "title": "",
            "source": "COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University",
            "category": "Confirmed cases",
            "owid_definition": "Total confirmed cases of COVID-19 per 1,000,000 people",
            "text": "This is the cumulative value of all confirmed COVID-19 cases reported by the official health ministries from the start of the pandemic. Total cases does not necessarily capture all active COVID-19 cases. Cases here means patients that have tested positive. There may be asymptomatic people who have not been tested and are therefore not accounted for here.  For this reason, the positive rate is often used as a more accurate indicator of the prevalence of the virus.\nCountries around the world each have different population sizes and comparing the total confirmed cases of COVID-19 between vastly different countries isn't necessarily helpful. By comparing per million, it helps give a relative size and allows better comparisons between countries."
        },
        {
            "name": "new_deaths",
            "type": "metric",
            "title": "",
            "source": "COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University",
            "category": "Confirmed deaths",
            "owid_definition": "New deaths attributed to COVID-19",
            "text": "This value accounts for all new COVID-19 deaths reported daily. This is different to total deaths which is cumulative. This data is not smoothed and it clearly shows the gaps where no data was reported on certain days. This is a useful indicator to show the frequency of reporting on new COVID-19 deaths.\nIn some countries, only COVID-19 deaths in hospitals are accounted for in this value. COVID-19 deaths at home, or in alternative care facilities are not included in this value. For this reason excess deaths are often a better indicator of actual COVID-19 deaths in a country. The data for excess deaths is unfortunately not widely available and not included in this dashboard."
        },
        {
            "name": "new_deaths_smoothed_per_million",
            "type": "metric",
            "title": "",
            "source": "COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University",
            "category": "Confirmed deaths",
            "owid_definition": "New deaths attributed to COVID-19 (7-day smoothed) per 1,000,000 people",
            "text": "This is different to total deaths which is cumulative. \nData smoothing can be defined as a statistical approach of eliminating outliers from datasets to make the patterns clearer.\n7-day smoothed: average of a 7 day period.\nA full 7 days needs to be included to obtain an accurate picture of the data for the week. \nThis is commonly applied to COVID-19 data because not all health facilities report new cases, deaths or tests on a daily basis. \nCountries around the world each have different population sizes and comparing the number of new COVID-19 deaths between vastly different countries isn't necessarily helpful. By comparing per million, it helps give a relative size and allows better comparisons between countries."
        },
        {
            "name": "total_deaths",
            "type": "metric",
            "title": "",
            "source": "COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University",
            "category": "Confirmed deaths",
            "owid_definition": "Total deaths attributed to COVID-19",
            "text": "This is the cumulative value of all COVID-19 deaths reported by the official health ministries from the start of the pandemic. Total deaths does not necessarily capture all COVID-19 deaths. In some countries, COVID-19 deaths in hospitals are only counted in this value. COVID-19 deaths at home, or in alternative care facilities are not included in this value. For this reason excess deaths are often a better indicator of actual COVID-19 deaths in a country. The data for excess deaths is unfortunately not widely available and not included in this dashboard.\nThis is an absolute value. It is better to use the total deaths per million when comparing to other countries."
        },
        {
            "name": "total_deaths_per_million",
            "type": "metric",
            "title": "",
            "source": "COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University",
            "category": "Confirmed deaths",
            "owid_definition": "Total deaths attributed to COVID-19 per 1,000,000 people",
            "text": "This is the cumulative value of all COVID-19 deaths reported by the official health ministries from the start of the pandemic. Total deaths does not necessarily capture all COVID-19 deaths. In some countries, COVID-19 deaths in hospitals are only counted in this value. COVID-19 deaths at home, or in alternative care facilities are not included in this value. For this reason excess deaths are often a better indicator of actual COVID-19 deaths in a country. The data for excess deaths is unfortunately not widely available and not included in this dashboard.\nCountries around the world each have different population sizes and comparing the total number of deaths between vastly different countries isn't necessarily helpful. By comparing per million, it helps give a relative size and allows better comparisons between countries."
        },
        {
            "name": "stringency_index",
            "type": "metric",
            "title": "",
            "source": "Oxford COVID-19 Government Response Tracker, Blavatnik School of Government",
            "category": "Policy responses",
            "owid_definition": "Government Response Stringency Index: composite measure based on 9 response indicators including school closures, workplace closures, and travel bans, rescaled to a value from 0 to 100 (100 = strictest response)",
            "text": "This index simply records the strictness of government policies. Nine metrics used in this index include: school closures; workplace closures; cancellation of public events; restrictions on public gatherings; closures of public transport; stay-at-home requirements; public information campaigns; restrictions on internal movements; and international travel controls.\nThe index on any given day is calculated as the mean score of the nine metrics, each taking a value between 0 and 100. \nA higher score indicates a stricter response (i.e. 100 = strictest response). A higher score for a country does not necessarily mean that a country’s response is ‘better’ than others lower on the index. This index does not measure or imply the appropriateness or effectiveness of a country’s response.\nIf policies vary at the subnational level, the index is shown as the response level of the strictest sub-region.\nA composite measure is a combination of two or more individual measures in a single measure that results in a single score.\nAnalysts can use this value to idenitfy when lockdown regulations / restrictions were lifted or enforced and what impact this has had upon the prevelance of new infections. In some cases, an increase in new cases is followed by an increase in the stringency index showing the responsiveness of governements."
        },
        {
            "name": "reproduction_rate",
            "type": "metric",
            "title": "",
            "source": "Arroyo Marioli et al. (2020). https://doi.org/10.2139/ssrn.3581633",
            "category": "Reproduction rate",
            "owid_definition": "Real-time estimate of the effective reproduction rate (R) of COVID-19. See https://github.com/crondonm/TrackingR/tree/main/Estimates-Database",
            "text": "The basic reproduction number (R) is a well-known epidemiological concept to measure the spread of an infectious disease. It is defined as the average number of secondary cases that one primary case will generate in a given population  i.e. how many people will be infected from one positive COVID-19 case in a specific population or country. \nA value of R above 1 implies an exponential growth in the number of cases of the virus in the population, while a value of R below 1 indicates that the virus will stop spreading, because not enough new people are being infected to sustain the outbreak (Locatelli, Trächsel & Rousson, 2021). \nThe R number is not fixed. Instead, it changes as human behaviour changes or as immunity develops. \nSince it's not possible to capture the moment people are infected, scientists work backwards instead. Data (such as the number of people dying, admitted to hospital or testing positive for the virus over time) is used to estimate how easily the virus is spreading."
        },
        {
            "name": "new_tests",
            "type": "metric",
            "title": "",
            "source": "National government reports",
            "category": "Tests & positivity",
            "owid_definition": "New tests for COVID-19 (only calculated for consecutive days)",
            "text": "This value accounts for all new COVID-19 tests reported daily. This is different to the total tests which is cumulative.\nThis data is not smoothed and it clearly shows the gaps where no data was reported on certain days. \nThis is a useful indicator to show the frequency of reporting on new COVID-19 tests."
        },
        {
            "name": "new_tests_smoothed",
            "type": "metric",
            "title": "",
            "source": "National government reports",
            "category": "Tests & positivity",
            "owid_definition": "New tests for COVID-19 (7-day smoothed). For countries that don't report testing data on a daily basis, we assume that testing changed equally on a daily basis over any periods in which no data was reported. This produces a complete series of daily figures, which is then averaged over a rolling 7-day window",
            "text": "This is different to the total tests which is cumulative.\nData smoothing can be defined as a statistical approach of eliminating outliers from datasets to make the patterns clearer. \n7-day smoothed: average of a 7 day period. \nA full 7 days needs to be included to obtain an accurate picture of the data for the week. \nA rolling 7-day window takes data from the last 7 days, adds it up, and divides it by 7. \nThis is commonly applied to COVID-19 data because not all health facilities report new cases, deaths or tests on a daily basis.\nThis value is a useful indicator to show the absolute number of new COVID-19 tests smoothed over a rolling 7-day window."
        },
        {
            "name": "positive_rate",
            "type": "metric",
            "title": "",
            "source": "National government reports",
            "category": "Tests & positivity",
            "owid_definition": "The share of COVID-19 tests that are positive, given as a rolling 7-day average (this is the inverse of tests_per_case)",
            "text": "Not every COVID-19 test yields a positive result, but without testing, we would have no data about how many active cases there are in a country. It is expected that the number of new confirmed cases will be lower than the number of tests.  If the positivity rate is high, governments might decide to increase testing. This could also signal a resurgence in infections. Simarly, a low positivity rate could signal a decrease in new infections if total tests remain constant. If testing does not keep up with rising numbers of new cases, there is a risk that many people may have COVID-19 without knowing it and without isolating from others. It is therefore important for us to know how the rate of testing compares to the number of positive COVID-19 cases. According to the World Health Organisation (WHO) (May 2020), a positive rate of less than 5% for two weeks is one indicator that the pandemic is under control in a country. \nThis value is given as a rolling 7-day average which takes data from the last 7 days, adds it up, and divides it by 7. This is commonly applied to COVID-19 data because not all health facilities report new cases, deaths or tests on a daily basis."
        },
        {
            "name": "total_tests",
            "type": "metric",
            "title": "",
            "source": "National government reports",
            "category": "Tests & positivity",
            "owid_definition": "Total tests for COVID-19",
            "text": "Total number of tests that have been conducted to assess whether someone has COVID-19 or not. This is the cumulative value of all COVID-19 tests reported by the official health ministries from the start of the pandemic. \nThe number of tests does not refer to the same in each country – one difference is that some countries report the number of people tested, while others report the number of tests performed (which can be higher if the same person is tested more than once). And other countries report their testing data in a way that leaves it unclear what the test count refers to exactly.\nDifferent countries include different types of tests in their reporting. For example, Zimbabwe reports on PCR and antigen COVID-19 tests while Senegal only reports on PCR testing."
        },
        {
            "name": "new_vaccinations",
            "type": "metric",
            "title": "",
            "source": "National government reports",
            "category": "Vaccinations",
            "owid_definition": "New COVID-19 vaccination doses administered (only calculated for consecutive days)",
            "text": "This value accounts for all new COVID-19 vaccinations reported daily. For vaccines that require more than one dose, each individual dose is counted. This is different to the total amount of vaccinations which is cumulative. This data is not smoothed and it clearly shows the gaps where no data was reported on certain days. \nThis is a useful indicator to show the frequency of reporting on new COVID-19 vaccinations."
        },
        {
            "name": "new_deaths_per_million",
            "type": "metric",
            "title": "",
            "source": "COVID-19 Data Repository by the Center for Systems Science and Engineering (CSSE) at Johns Hopkins University",
            "category": "Confirmed deaths",
            "owid_definition": "New deaths attributed to COVID-19 per 1,000,000 people",
            "text": ""
        },
        {
            "name": "new_vaccinations_smoothed_per_million",
            "type": "metric",
            "title": "",
            "source": "National government reports",
            "category": "Vaccinations",
            "owid_definition": "New COVID-19 vaccination doses administered (7-day smoothed) per 1,000,000 people in the total population",
            "text": "For vaccines that require more than one dose, each individual dose is counted.\nData smoothing can be defined as a statistical approach of eliminating outliers from datasets to make the patterns clearer.\n7-day smoothed: average of a 7 day period.\nA full 7 days needs to be included to obtain an accurate picture of the data for the week. \nThis is commonly applied to COVID-19 data because not all health facilities report new cases, deaths or tests on a daily basis. \nCountries around the world each have different population sizes and comparing the number of new COVID-19 vaccination doses administered between vastly different countries isn't necessarily helpful. By comparing per million, it helps give a relative size and allows better comparisons between countries."
        },
        {
            "name": "people_fully_vaccinated",
            "type": "metric",
            "title": "",
            "source": "National government reports",
            "category": "Vaccinations",
            "owid_definition": "Total number of people who received all doses prescribed by the vaccination protocol",
            "text": "This value accounts for only those who have been fully vaccinated. This means that they have received the vaccine that only requires one dose or they have had both doses of a two dose vaccine."
        },
        {
            "name": "people_vaccinated",
            "type": "metric",
            "title": "",
            "source": "National government reports",
            "category": "Vaccinations",
            "owid_definition": "Total number of people who received at least one vaccine dose",
            "text": "Some vaccinations require only one dose while others require two doses. The number here could therefore indicate a mix of those who are fully vaccinated (received the vaccine that only requires one dose) and those who are partially vaccinated (have received the first of two vaccinations)."
        },
        {
            "name": "total_vaccinations",
            "type": "metric",
            "title": "",
            "source": "National government reports",
            "category": "Vaccinations",
            "owid_definition": "Total number of COVID-19 vaccination doses administered",
            "text": "This is the cumulative value of all COVID-19 vaccinations reported by the official health ministries. This value is the total amount of vaccination doses (first and second doses included) that have been administered. \nThis is an absolute value. It is better to use the total vaccinations per million when comparing to other countries."
        }
    ],
    "acdc": [{
            "name": "new_cases",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed cases",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_cases_smoothed",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed cases",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_cases_per_million",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed cases",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_cases_smoothed_per_million",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed cases",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "total_cases",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed cases",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "total_cases_per_million",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed cases",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "total_cases_per_million",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed cases",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_deaths",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed deaths",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_deaths_smoothed",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed deaths",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_deaths_per_million",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed deaths",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_deaths_smoothed_per_million",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed deaths",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "total_deaths",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed deaths",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "total_deaths_per_million",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Confirmed deaths",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_vaccinations",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Vaccinations",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_people_vaccinated_smoothed",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Vaccinations",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_people_vaccinated_smoothed_per_hundred",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Vaccinations",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "total_boosters",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Vaccinations",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "total_boosters_per_hundred",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Vaccinations",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_tests",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Tests &amp; Positivity",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_tests_smoothed",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Tests &amp; Positivity",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_tests_per_thousand",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Tests &amp; Positivity",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "new_tests_smoothed_per_thousand",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Tests &amp; Positivity",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "total_tests",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Tests &amp; Positivity",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "total_tests_per_thousand",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Tests &amp; Positivity",
            "acdc_definition": "...",
            "text": "..."
        },
        {
            "name": "positive_rate",
            "type": "metric",
            "title": "",
            "source": "AfricaCDC",
            "category": "Tests &amp; Positivity",
            "acdc_definition": "...",
            "text": "..."
        }
    ]
}